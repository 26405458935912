<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</div>

<section class="contact-area ptb-120">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">Message Us</span>
            <h2>Drop us message for any query</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>
        </div>

        <form id="contactForm">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Your Name" name="name" id="name">
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Your Email Address" name="email" id="email">
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" name="phone_number" placeholder="Phone Number" id="phone_number">
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" name="msg_subject" placeholder="Subject" id="msg_subject">
                    </div>
                </div>
                
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="10" placeholder="Type Your Message Here"></textarea>
                    </div>
                </div>
                
                <div class="col-lg-12 col-md-12">
                    <button type="submit" class="btn btn-primary">Send Message</button>
                </div>
            </div>
        </form>

        <div class="contact-info">
            <div class="section-title text-center">
                <h2>Don't Hesitate to contact with us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>
            </div>

            <div class="contact-info-content">
                <h3>Call us for imidiate support to this number</h3>
                <h2><a href="tel:+8843212545414">+884 321 254 5414</a></h2>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3107.602743187908!2d-76.917249784309!3d38.84155785783816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7bea0669d87bf%3A0x274072d8091a0786!2sSuitland%20Rd%2C%206%2C%20Spauldings%2C%20MD%2C%20USA!5e0!3m2!1sen!2sbd!4v1599555419389!5m2!1sen!2sbd"></iframe>
</div>