<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-coding"></i>
                    </div>
                    <h3>Advanced Robotics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <img src="assets/img/shape-image/9.png" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon bg-24b765">
                        <i class="flaticon-sugar-blood-level"></i>
                    </div>
                    <h3>Diabetes Testing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <img src="assets/img/shape-image/9.png" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon bg-f59f00">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3>Pathology Testing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <img src="assets/img/shape-image/9.png" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon bg-f7b232">
                        <i class="flaticon-microscope"></i>
                    </div>
                    <h3>Healthcare Lab</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <img src="assets/img/shape-image/9.png" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon bg-fe5d24">
                        <i class="flaticon-green-earth"></i>
                    </div>
                    <h3>Alternative Energy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <img src="assets/img/shape-image/9.png" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon bg-45c27c">
                        <i class="flaticon-ai"></i>
                    </div>
                    <h3>Artificial Intelligent</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <img src="assets/img/shape-image/9.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>