<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box bg-3fc581">
                    <div class="icon">
                        <i class="flaticon-blood-pressure"></i>
                    </div>
                    <h3>Basic Blood Profiles</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Fasting Required</li>
                        <li><i class="flaticon-check-mark-1"></i> Report Available</li>
                        <li><i class="flaticon-check-mark-1"></i> Receive Report by Email</li>
                    </ul>
                    <div class="price">
                        79$ <span>/Monthly</span>
                    </div>
                    <a routerLink="/pricing" class="default-btn">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="icon">
                        <i class="flaticon-health"></i>
                    </div>
                    <h3>Gold Health Check</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Fasting Required</li>
                        <li><i class="flaticon-check-mark-1"></i> Report Available</li>
                        <li><i class="flaticon-check-mark-1"></i> Receive Report by Email</li>
                    </ul>
                    <div class="price">
                        89$ <span>/Monthly</span>
                    </div>
                    <a routerLink="/pricing" class="default-btn">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-box bg-f59f00">
                    <div class="icon">
                        <i class="flaticon-health-check"></i>
                    </div>
                    <h3>Platinum Health Check</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Fasting Required</li>
                        <li><i class="flaticon-check-mark-1"></i> Report Available</li>
                        <li><i class="flaticon-check-mark-1"></i> Receive Report by Email</li>
                    </ul>
                    <div class="price">
                        99$ <span>/Monthly</span>
                    </div>
                    <a routerLink="/pricing" class="default-btn">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
</section>