<footer class="footer-area bg-fffcfb">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo@2x.png" alt="logo"  style="max-height: 60px;"></a>
                        <p>We provide cell manipulation services, genetic mutations, regenerative medicine (but only the dangerous kind) and anything that involves unnecessary risk.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5 ml-3">
                    <h3>Useful Links</h3>

                    <ul class="useful-links-list">
                        <li><a routerLink="/about-us">About</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/team">Research</a></li>
<!--                        <li><a routerLink="/contact">Donate Passaway</a></li>-->
<!--                        <li><a routerLink="/contact">Donate PLASM</a></li>-->
                        <li><a routerLink="/research">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-3">
                    <h3>Our Services</h3>

                    <ul class="widget-services-list">
                        <li><a routerLink="/manipulation">Cell Manipulation</a></li>
                        <li><a routerLink="/mutations">Genetic Mutations</a></li>
                        <li><a routerLink="/mind-control">Mind Control</a></li>
                        <li><a routerLink="/immortality">Immortality</a></li>
                        <li><a routerLink="/invisibility">Invisibility</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="flaticon-facebook-placeholder-for-locate-places-on-maps"></i> Everywhere</li>
<!--                        <li><i class="flaticon-phone"></i> <a href="">+1 212 666 7734</a></li>-->
<!--                        <li><i class="flaticon-close-envelope"></i> <a href="mailto:admin@7734initative.com">admin@7734initative.com</a></li>-->
                        <li><i class="flaticon-internet"></i> <a routerLink="/">www.7734initative.com</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p><a href="/" target="_blank">7734 Initiative</a> © 2022. All rights reserved.</p>
                </div>


            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
