import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SeizedComponent } from './components/pages/seized/seized.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'seized', component: SeizedComponent},
    {path: '**', component: SeizedComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
