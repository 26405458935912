<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Chemical Research</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Chemical Research</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-details-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details">
                    <img src="assets/img/services-details1.jpg" alt="image">

                    <div class="services-details-desc">
                        <h3>Whenever it comes to financial matters, customer Engagement matters more than more.</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.</p>
                        <h3>Research about project</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p>
                        <div class="services-details-faq">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <span class="accordion-title">Branding is simply a more efficient way?</span>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.</p>
                                </li>
                                <li class="accordion-item">
                                    <span class="accordion-title">Why we are the best in this fields?</span>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.</p>
                                </li>
                                <li class="accordion-item">
                                    <span class="accordion-title">It’s better to be first in the mind than in the market?</span>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.</p>
                                </li>
                            </ul>
                        </div>

                        <div class="services-details-features-list">
                            <ul>
                                <li><i class="flaticon-check-mark"></i> Rat/Mouse PINP EIA</li>
                                <li><i class="flaticon-check-mark"></i> Rat/Mouse PINP EIA</li>
                                <li><i class="flaticon-check-mark"></i> IDS-iSYS PTH (1-34)</li>
                                <li><i class="flaticon-check-mark"></i> IDS-iSYS PTH (1-34)</li>
                                <li><i class="flaticon-check-mark"></i> Corticosterone EIA</li>
                                <li><i class="flaticon-check-mark"></i> Corticosterone EIA</li>
                                <li><i class="flaticon-check-mark"></i> Corticosterone HS</li>
                                <li><i class="flaticon-check-mark"></i> Corticosterone HS</li>
                                <li><i class="flaticon-check-mark"></i> Urine CartiLaps EIA</li>
                                <li><i class="flaticon-check-mark"></i> Urine CartiLaps EIA</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-sidebar">
                    <div class="services-list">
                        <ul>
                            <li><a routerLink="/single-services" class="active">Chemical Research <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/single-services">Pathology Testing  <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/single-services">Artificial Intelligence <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/single-services">Diabetes Testing <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/single-services">Advanced Robotics <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/single-services">Pathology Testing <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/single-services">Alternative Energy <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>

                    <div class="services-download-list">
                        <ul>
                            <li><a routerLink="/single-services"><i class="flaticon-pdf"></i> Download Pdf File</a></li>
                            <li><a routerLink="/single-services"><i class="flaticon-txt"></i> Download Txt File</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>