<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Analytical Balances</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Shop Details</li>
            </ul>
        </div>
    </div>
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="product-details-image">
                    <img src="assets/img/shop-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                    <h3>Analytical Balances</h3>
                    <div class="price">
                        <span class="new-price">$14.00</span>
                        <span class="old-price">$20.00</span>
                    </div>
                    <div class="product-review">
                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div>
                        <a routerLink="/single-products" class="rating-count">3 reviews</a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et. I agree with the terms and conditions. I agree with the terms and conditions. I agree with the terms and conditions.</p>
                    <div class="product-add-to-cart">
                        <div class="input-counter">
                            <span class="minus-btn"><i class="fas fa-minus"></i></span>
                            <input type="text" min="1" value="1">
                            <span class="plus-btn"><i class="fas fa-plus"></i></span>
                        </div>
                        <button type="submit" class="btn btn-primary"><i class="fas fa-cart-plus"></i> Add to Cart</button>
                    </div>
                    <div class="buy-checkbox-btn">
                        <div class="item">
                            <input class="inp-cbx" id="cbx" type="checkbox">
                            <label class="cbx" for="cbx">
                                <span>
                                    <svg width="12px" height="10px" viewbox="0 0 12 10">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span>I agree with the terms and conditions</span>
                            </label>
                        </div>
                        <div class="item">
                            <a routerLink="/single-products" class="btn btn-secondary">Buy it now!</a>
                        </div>
                    </div>
                    <div class="custom-payment-options">
                        <span>Guaranteed safe checkout:</span>
                        <div class="payment-methods">
                            <a href="#"><img src="assets/img/payment-image/1.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment-image/2.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment-image/3.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment-image/4.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment-image/5.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment-image/6.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment-image/7.svg" alt="image"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <div class="dot"></div> Description
                        </a></li>
                        <li><a href="#">
                            <div class="dot"></div> Additional information
                        </a></li>
                        <li><a href="#">
                            <div class="dot"></div> Reviews
                        </a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <p>Design inspiration lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar odio lorem non turpis. Nullam sit amet enim. Suspendisse id velit vitae ligula volutpat condimentum.  Aliquam erat volutpat. Sed quis velit. Nulla facilisi. Nulla libero. Vivamus pharetra posuere sapien. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nulla libero. Vivamus pharetra posuere sapien.</p>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <ul class="additional-information">
                                    <li><span>Brand:</span> ThemeForest</li>
                                    <li><span>Color:</span> Brown</li>
                                    <li><span>Size:</span> Large, Medium</li>
                                    <li><span>Weight:</span> 27 kg</li>
                                    <li><span>Dimensions:</span> 16 x 22 x 123 cm</li>
                                </ul>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="product-review-form">
                                    <h3>Customer Reviews</h3>
                                    <div class="review-title">
                                        <div class="rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="far fa-star"></i>
                                        </div>
                                        <p>Based on 3 reviews</p>
                                        <a routerLink="/single-products" class="btn btn-secondary">Write a Review</a>
                                    </div>

                                    <div class="review-comments">
                                        <div class="review-item">
                                            <div class="rating">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2019</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            <a routerLink="/" class="review-report-link">Report as Inappropriate</a>
                                        </div>

                                        <div class="review-item">
                                            <div class="rating">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2019</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            <a routerLink="/" class="review-report-link">Report as Inappropriate</a>
                                        </div>

                                        <div class="review-item">
                                            <div class="rating">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2019</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            <a routerLink="/" class="review-report-link">Report as Inappropriate</a>
                                        </div>
                                    </div>

                                    <div class="review-form">
                                        <h3>Write a Review</h3>
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <label>Name</label>
                                                        <input type="text" id="name" name="name" placeholder="Enter your name" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input type="email" id="email" name="email" placeholder="Enter your email" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="review-rating">
                                                        <p>Rate this item</p>
                                
                                                        <div class="star-source">
                                                            <svg>
                                                                <linearGradient x1="50%" y1="5.41294643%" x2="87.5527344%" y2="65.4921875%" id="grad">
                                                                    <stop stop-color="#f2b01e" offset="0%"></stop>
                                                                    <stop stop-color="#f2b01e" offset="60%"></stop>
                                                                    <stop stop-color="#f2b01e" offset="100%"></stop>
                                                                </linearGradient>
                                                                <symbol id="star" viewBox="153 89 106 108">   
                                                                    <polygon id="star-shape" stroke="url(#grad)" stroke-width="5" fill="currentColor" points="206 162.5 176.610737 185.45085 189.356511 150.407797 158.447174 129.54915 195.713758 130.842203 206 95 216.286242 130.842203 253.552826 129.54915 222.643489 150.407797 235.389263 185.45085"></polygon>
                                                                </symbol>
                                                            </svg>
                                                        </div>

                                                        <div class="star-rating">
                                                            <input type="radio" name="star" id="five">
                                                            <label for="five">
                                                                <svg class="star">
                                                                    <use xlink:href="#star"/>
                                                                </svg>
                                                            </label>

                                                            <input type="radio" name="star" id="four">
                                                            <label for="four">
                                                                <svg class="star">
                                                                    <use xlink:href="#star"/>
                                                                </svg>
                                                            </label>

                                                            <input type="radio" name="star" id="three">
                                                            <label for="three">
                                                                <svg class="star">
                                                                    <use xlink:href="#star"/>
                                                                </svg>
                                                            </label>

                                                            <input type="radio" name="star" id="two">
                                                            <label for="two">
                                                                <svg class="star">
                                                                    <use xlink:href="#star" />
                                                                </svg>
                                                            </label>

                                                            <input type="radio" name="star" id="one">
                                                            <label for="one">
                                                                <svg class="star">
                                                                    <use xlink:href="#star" />
                                                                </svg>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Review Title</label>
                                                        <input type="text" id="review-title" name="review-title" placeholder="Enter your review a title" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Body of Review (1500)</label>
                                                        <textarea name="review-body" id="review-body" cols="30" rows="7" placeholder="Write your comments here" class="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" class="btn btn-primary">Submit Review</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="related-products-area">
        <div class="container">
            <div class="section-title">
                <span>Our Shop</span>
                <h2>Related Products</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                            <span class="sale-btn">Sale!</span>
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Analytical Balances 2</a></h3>
                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                            <span class="price">$14.00</span>
                            <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop-img6.jpg" alt="image">
                            </a>
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Precision Balances 1</a></h3>
                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star-half-alt"></i>
                            </div>
                            <span class="price">$20.00</span>
                            <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop-img3.jpg" alt="image">
                            </a>
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Precision Balances 2</a></h3>
                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                            </div>
                            <span class="price">$25.00</span>
                            <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>