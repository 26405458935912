<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team</li>
            </ul>
        </div>
    </div>
</div>

<section class="team-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img1.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Agaton Ronald</a></h3>
                        <span>Dental Assistant</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img2.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Saray Taylor</a></h3>
                        <span>Dentist Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img3.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Robert Reed</a></h3>
                        <span>Neck Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img4.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Joe Root</a></h3>
                        <span>Medicine Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img5.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Heather Knight</a></h3>
                        <span>Dental Assistant</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img6.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Mark Robinson</a></h3>
                        <span>Dentist Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img7.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Amy Jones</a></h3>
                        <span>Neck Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img8.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Robert Reed</a></h3>
                        <span>Medicine Expert</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>