<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Remember, Only YOU can Prevent Genetic Drift</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-image">
                        <img src="assets/img/blog-img9.jpg" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class="far fa-user-circle"></i> By: <a routerLink="/blog-1">Admin</a></li>
                                <li>7 Aug 2019</li>
                                <li><a routerLink="/blog-1">Tech</a></li>
                                <li>2 Mins Read</li>
                                <li>521 Views</li>
                                <li><a routerLink="/single-blog">3 Comments</a></li>
                            </ul>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <cite>Tom Cruise</cite>
                        </blockquote>
                        <p>Equidem impedit officiis quo te. Illud partem sententiae mel eu, euripidis urbanitas et sit. Mediocrem reprimique an vim, veniam tibique omittantur duo ut, agam graeci in vim. Quot appetere patrioque te mea, animal aliquip te pri. Ad vis animal ceteros percipitur, eos tollit civibus percipitur no.</p>
                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-img8.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-img7.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-img6.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>
                        <h3>10 Tactics for marketing your company</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.</p>
                        <ul class="article-features-list">
                            <li>Devices can grind down to a halt.</li>
                            <li>Small companies Reairing business.</li>
                            <li>Repairs are competitive with anyone</li>
                            <li>Repair broken buttons, speakers</li>
                            <li>Repairs are competitive with anyone</li>
                        </ul>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class="fas fa-bookmark"></i></span>
                            <a routerLink="/blog-1">Fashion</a>,
                            <a routerLink="/blog-1">Games</a>,
                            <a routerLink="/blog-1">Travel</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/single-blog"><i class="flaticon-left-arrow"></i> Prev Post</a>
                            </div>
                            <div class="nav-next">
                                <a routerLink="/single-blog">Next Post <i class="flaticon-right-arrow"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="comments-area">
                    <h3 class="comments-title">2 Comments:</h3>

                    <ol class="comment-list">
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author1.jpg" class="avatar" alt="image">
                                        <b class="fn">John Jones</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a routerLink="/blog-1"><time>April 24, 2019 at 10:59 am</time></a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                </div>
                            </article>

                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author2.jpg" class="avatar" alt="image">
                                                <b class="fn">Steven Smith</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a routerLink="/blog-1"><time>April 24, 2019 at 10:59 am</time></a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/author3.jpg" class="avatar" alt="image">
                                                    <b class="fn">Sarah Taylor</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-1"><time>April 24, 2019 at 10:59 am</time></a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                            <div class="reply">
                                                <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </ol>
                        </li>

                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author2.jpg" class="avatar" alt="image">
                                        <b class="fn">John Doe</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a routerLink="/blog-1"><time>April 24, 2019 at 10:59 am</time></a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                </div>
                            </article>

                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author4.jpg" class="avatar" alt="image">
                                                <b class="fn">James Anderson</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a routerLink="/blog-1"><time>April 24, 2019 at 10:59 am</time></a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <div class="comment-respond">
                        <h3 class="comment-reply-title">Leave a Reply</h3>

                        <form class="comment-form">
                            <p class="comment-notes">
                                <span id="email-notes">Your email address will not be published.</span>
                                Required fields are marked 
                                <span class="required">*</span>
                            </p>
                            <p class="comment-form-comment">
                                <label>Comment</label>
                                <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                            </p>
                            <p class="comment-form-author">
                                <label>Name <span class="required">*</span></label>
                                <input type="text" id="author" name="author" required="required">
                            </p>
                            <p class="comment-form-email">
                                <label>Email <span class="required">*</span></label>
                                <input type="email" id="email" name="email" required="required">
                            </p>
                            <p class="comment-form-url">
                                <label>Website</label>
                                <input type="url" id="url" name="url">
                            </p>
                            <p class="comment-form-cookies-consent">
                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                            </p>
                            <p class="form-submit">
                                <input type="submit" name="submit" id="submit" class="submit" value="Post Comment">
                            </p>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_zylon_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How to change yourself for the better</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">10 Tactics for marketing your company</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Google web ranking changing much</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_recent_comments">
                        <h3 class="widget-title">Recent Comments</h3>

                        <ul>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">A WordPress Commenter</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">Semental</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">Wordpress</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">A WordPress Commenter</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">Semental</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/single-blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/single-blog">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/single-blog">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/single-blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/single-blog">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-1">May 2019</a></li>
                            <li><a routerLink="/blog-1">April 2019</a></li>
                            <li><a routerLink="/blog-1">June 2019</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-1">Business</a></li>
                            <li><a routerLink="/blog-1">Privacy</a></li>
                            <li><a routerLink="/blog-1">Technology</a></li>
                            <li><a routerLink="/blog-1">Tips</a></li>
                            <li><a routerLink="/blog-1">Uncategorized</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/blog-1">Log in</a></li>
                            <li><a routerLink="/blog-1">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog-1">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog-1">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog-1">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-1">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-1">IT <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-1">Marketing <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-1">Mobile <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Protect <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Startup <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>