<div class="main-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <span>Current experiment</span>
                    <h1>
                        <label>Yes, we’ve created</label>
                        <br/>
                        <label>a monster.</label>
                    </h1>
                    <p class="not-mobile-only">Some would call us unethical scientists. Even irresponsible human beings.<br/>
                        But before you make any judgements, please look into the eyes.</p>
                    <p class="mobile-only">Some would call us unethical scientists. Even irresponsible human beings.
                        But before you make any judgements, please look into the eyes.</p>
                    <a routerLink="/learn-more" class="btn btn-primary">LEARN MORE</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets/img/banner-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="boxes-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box bg-71009b">
                    <div class="icon">
                        <img src="assets/img/mind-control@2x.png" style="height: 52px"/>
                    </div>
                    <h3>Mind Control</h3>
                    <p>Whether you want to win arguments with your spouse or win the love that special someone who thinks you’re disgusting. We’ve got you covered.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box bg-bf00b8">
                    <div class="icon">
                        <img src="assets/img/immortality@2x.png" style="height: 33px"/>
                    </div>
                    <h3>Immortality</h3>
                    <p>Many of our gullible and mega-rich clients love our Immortality for the Wealthy™ plan. Just ask. We only take PLASM, though.</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3 col-sm-6 offset-sm-3">
                <div class="single-box bg-471b9e">
                    <div class="icon">
                        <img src="assets/img/invisibility@2x.png" style="height: 50px"/>
                    </div>
                    <h3>Invisibility</h3>
                    <p>Always not there. Never always here. Exactly! That’s what our happy clients have to say. Enjoy a free consultation and meet with an empty chair - or is it?</p>
                    <a routerLink="/single-services" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="testimonial-area ptb-120 pt-0" style="margin-top: 100px">
    <div class="container">
        <div class="section-title text-center">
            <h2>What customers are whispering</h2>
        </div>
    </div>

    <div class="testimonial-slides owl-carousel owl-theme">
        <div class="single-testimonial-item">
            <p>The tongue freaked me out.</p>
            <div class="client-info">
                <img src="assets/img/customers/01.png" alt="image">
                <h3>James Andeson</h3>
                <span>Barista</span>
            </div>
        </div>

        <div class="single-testimonial-item">
            <p>Horns everywhere. 7734 for LIFE.</p>
            <div class="client-info">
                <img src="assets/img/customers/02.png" alt="image">
                <h3>Danis Max</h3>
                <span>Freakshow</span>
            </div>
        </div>

        <div class="single-testimonial-item">
            <p>It took me to another dimension.</p>
            <div class="client-info">
                <img src="assets/img/customers/03.png" alt="image">
                <h3>Sarah Taylor</h3>
                <span>Stalker</span>
            </div>
        </div>

        <div class="single-testimonial-item">
            <p>Don't eat it.</p>

            <div class="client-info">
                <img src="assets/img/customers/04.png" alt="image">
                <h3>Joe Root</h3>
                <span>Foodie</span>
            </div>
        </div>

        <div class="single-testimonial-item">
            <p>Blood and PLASM goo.</p>
            <div class="client-info">
                <img src="assets/img/customers/05.png" alt="image">
                <h3>Jackie Sims</h3>
                <span>Bartender</span>
            </div>
        </div>
    </div>
</section>

