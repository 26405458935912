<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Shop</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Shop</li>
            </ul>
        </div>
    </div>
</div>

<section class="shop-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="woocommerce-topbar">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-7">
                            <div class="woocommerce-result-count">
                                <h3>Shop</h3>
                                <p>Showing 1-8 of 14 results</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-5">
                            <div class="woocommerce-topbar-ordering">
                                <select>
                                    <option value="1">Default sorting</option>
                                    <option value="2">Sort by popularity</option>
                                    <option value="0">Sort by average rating</option>
                                    <option value="3">Sort by latest</option>
                                    <option value="4">Sort by price: low to high</option>
                                    <option value="5">Sort by price: high to low</option>
                                    <option value="6">Sort by new</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop-img1.jpg" alt="image">
                                </a>
                                <span class="sale-btn">Sale!</span>
                            </div>
                            <div class="product-content">
                                <h3><a routerLink="/single-products">Analytical Balances 2</a></h3>
                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                                <span class="price">$14.00</span>
                                <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop-img6.jpg" alt="image">
                                </a>
                            </div>
                            <div class="product-content">
                                <h3><a routerLink="/single-products">Precision Balances 1</a></h3>
                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star-half-alt"></i>
                                </div>
                                <span class="price">$20.00</span>
                                <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop-img3.jpg" alt="image">
                                </a>
                            </div>
                            <div class="product-content">
                                <h3><a routerLink="/single-products">Precision Balances 2</a></h3>
                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="far fa-star"></i>
                                </div>
                                <span class="price">$25.00</span>
                                <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop-img4.jpg" alt="image">
                                </a>
                            </div>
                            <div class="product-content">
                                <h3><a routerLink="/single-products">Precision Balances 3</a></h3>
                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star-half-alt"></i>
                                    <i class="far fa-star"></i>
                                </div>
                                <span class="price">$10.00</span>
                                <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop-img5.jpg" alt="image">
                                </a>
                            </div>
                            <div class="product-content">
                                <h3><a routerLink="/single-products">Analytical Balances 1</a></h3>
                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star-half-alt"></i>
                                    <i class="far fa-star"></i>
                                </div>
                                <span class="price">$25.00</span>
                                <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop-img2.jpg" alt="image">
                                </a>
                            </div>
                            <div class="product-content">
                                <h3><a routerLink="/single-products">Rotavapor Laboratory</a></h3>
                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star-half-alt"></i>
                                    <i class="far fa-star"></i>
                                </div>
                                <span class="price">$22.00</span>
                                <a routerLink="/cart" class="add-to-cart-btn btn btn-secondary">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/products" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/products" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/products" class="page-numbers">3</a>
                            <a routerLink="/products" class="page-numbers">4</a>
                            <a routerLink="/products" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>