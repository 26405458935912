<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team Details</li>
            </ul>
        </div>
    </div>
</div>

<section class="team-details-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="team-details-sidebar">
                    <div class="team-profile">
                        <img src="assets/img/team-img1.jpg" alt="image">
                        <div class="team-content">
                            <h3>Agaton Ronald</h3>
                            <p>MBBS, M.D of Medicine</p>
                            <span>BDS, FCPS (Hons), PhD (USA)</span>
                        </div>
                    </div>
                    <div class="social-box">
                        <h3>Social</h3>
                        <ul>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="call-to-action-box">
                        <a href="tel:+28885555555">
                            <i class="fas fa-headset"></i>
                            <h3>Emergency Cases</h3>
                            <span>+2 888 555 5555</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="team-details-desc">
                    <h3>About Me</h3>
                    <p>Efficiently myocardinate market-driven innovation via open-source alignments. Dramatically engage  high-Phosfluorescently expedite impactful supply chains via focused results. Holistically . Compellingly supply just in time catalysts for change through.</p>
                    <ul class="team-info">
                        <li>
                            <span>Speciality</span>
                            <ul>
                                <li>Endocrinology</li>
                                <li>Paediatric Medicine</li>
                                <li>Urology</li>
                            </ul>
                        </li>
                        <li>
                            <span>Education</span>
                            <ul>
                                <li>Doctor of Medicine, University of Texas, USA (1990)</li>
                                <li>Medical Orientation Program, St. Louis University (St. Louis, Missouri 1996)</li>
                            </ul>
                        </li>
                        <li>
                            <span>Experience</span>
                            <ul>
                                <li>25 years of Experience in Medicine</li>
                            </ul>
                        </li>
                        <li>
                            <span>Education</span>
                            <ul>
                                <li>Vice President and Chief Medical Officer, Kessler Institute for Rehabilitation</li>
                                <li>Medical Corporation Professor, Institute Of Coast Private Hospital Campus</li>
                            </ul>
                        </li>
                        <li>
                            <span>Address</span>
                            <ul>
                                <li>Suite 27, Medical Centre, The Sunshine Coast Private Hospital, QLD 4556</li>
                            </ul>
                        </li>
                        <li>
                            <span>Phone</span>
                            <ul>
                                <li><a href="tel:+1233456789">+1-23-345-6789</a></li>
                            </ul>
                        </li>
                        <li>
                            <span>Email</span>
                            <ul>
                                <li><a href="mailto:myemail@yourdomain.com">myemail@yourdomain.com</a></li>
                            </ul>
                        </li>
                        <li>
                            <span>Website</span>
                            <ul>
                                <li><a target="_blank" href="#">www.agatonronald.com</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="team-details-contact">
                    <h3>Contact Me</h3>
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name" name="name" id="name">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email Address" name="email" id="email">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="phone_number" placeholder="Phone Number" id="phone_number">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="msg_subject" placeholder="Subject" id="msg_subject">
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="10" placeholder="Type Your Message Here"></textarea>
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>