<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Organic Synthesis</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Research Details</li>
            </ul>
        </div>
    </div>
</div>

<section class="research-details-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="research-details-image">
                    <img src="assets/img/blog-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="research-details-info">
                    <h3>Information</h3>
                    <ul>
                        <li>
                            <i class="flaticon-check-mark"></i>
                            <span>Research Name</span>
                            Organic Synthesis
                        </li>
                        <li>
                            <i class="flaticon-check-mark"></i>
                            <span>Client</span>
                            Michale
                        </li>
                        <li>
                            <i class="flaticon-check-mark"></i>
                            <span>Category</span>
                            Oncology
                        </li>
                        <li>
                            <i class="flaticon-check-mark"></i>
                            <span>Delivery Mode</span>
                            Stipulated Price
                        </li>
                        <li>
                            <i class="flaticon-check-mark"></i>
                            <span>Location</span>
                            USA
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="research-details-features">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-box">
                                <div class="icon">
                                    <i class="flaticon-coding"></i>
                                </div>
                                <h3>Lab Technicians</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                                <img src="assets/img/shape-image/9.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-box">
                                <div class="icon bg-24b765">
                                    <i class="flaticon-sugar-blood-level"></i>
                                </div>
                                <h3>Research Center</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                                <img src="assets/img/shape-image/9.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="features-box">
                                <div class="icon bg-f59f00">
                                    <i class="flaticon-computer"></i>
                                </div>
                                <h3>Our Experience</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.</p>
                                <img src="assets/img/shape-image/9.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="research-details-desc">
                    <h3>Challenge & Solution</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <h3>Exerci tation ullamcorper suscipit lobortis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Occaecat sint occaecat suscipit dolore</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <h3>Being a top us private facility for any kind</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Our Process</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                </div>

                <div class="research-details-desc-footer">
                    <div class="article-tags">
                        <a routerLink="/research">Fashion</a>
                        <a routerLink="/research">Games</a>
                        <a routerLink="/research">Travel</a>
                    </div>

                    <div class="article-share">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="post-navigation">
                    <div class="navigation-links">
                        <div class="nav-previous">
                            <a routerLink="/single-research"><i class="flaticon-left-arrow"></i> Prev Post</a>
                        </div>
                        <div class="nav-next">
                            <a routerLink="/single-research">Next Post <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="related-project">
        <div class="container">
            <div class="section-title text-center">
                <span>Research</span>
                <h2>Related Project</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                <div class="single-research-box">
                    <div class="research-image">
                        <a routerLink="/single-research"><img src="assets/img/blog-img1.jpg" alt="image"></a>
                    </div>
                    <div class="research-content">
                        <span>Pathology</span>
                        <h3><a routerLink="/single-research">Organic Synthesis</a></h3>
                        <p>Enhancing Your Vision sit ametcon sec tetur adipisicing.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-research-box">
                    <div class="research-image">
                        <a routerLink="/single-research"><img src="assets/img/blog-img2.jpg" alt="image"></a>
                    </div>
                    <div class="research-content">
                        <span>Oncology</span>
                        <h3><a routerLink="/single-research">Laboratory quality control</a></h3>
                        <p>Enhancing Your Vision sit ametcon sec tetur adipisicing.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-research-box">
                    <div class="research-image">
                        <a routerLink="/single-research"><img src="assets/img/blog-img3.jpg" alt="image"></a>
                    </div>
                    <div class="research-content">
                        <span>Incubator</span>
                        <h3><a routerLink="/single-research">Cardiovascular disease</a></h3>
                        <p>Enhancing Your Vision sit ametcon sec tetur adipisicing.</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>