<div class="navbar-area">
    <div class="zylon-mobile-nav">
        <div class="logo">
            <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
        </div>
    </div>

    <div class="zylon-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo@2x.png" alt="logo" style="max-height: 60px;"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/research" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Research</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>
                        <li class="nav-item"><a routerLink="/mind-control" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mind Control</a></li>
                        <li class="nav-item"><a routerLink="/immortality" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Immortality</a></li>
                        <li class="nav-item"><a routerLink="/invisibility" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Invisibility</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
