<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<section class="about-our-company ptb-120 pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-company-content">
                    <span>About Company</span>
                    <h2>About us we are the Trusted Experts</h2>
                    <p><b>Laboratories used for scientific research take many forms because of the differing requirements of specialists.</b></p>
                    <p>Laboratories used for scientific research take many forms because of the differing requirements of specialists in the various fields of science and engineering.Lorem ipsum dolor.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-company-image">
                    <img src="assets/img/about-img2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="about-our-company-inner">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-inner-company-image">
                        <img src="assets/img/about-img3.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-inner-company-content">
                        <h2>We Discoveries We Give Your Solution</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>

    <div class="map-box1"><img src="assets/img/map1.png" alt="image"></div>
</section>

<section class="team-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span>Meet Our Team</span>
            <h2>Led by Passionate Experts</h2>
            <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the pleasure of the desire that they cannot foresee.</p>
            <a routerLink="/team" class="btn btn-secondary">Meet All</a>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img1.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Agaton Ronald</a></h3>
                        <span>Dental Assistant</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img2.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Saray Taylor</a></h3>
                        <span>Dentist Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img3.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Robert Reed</a></h3>
                        <span>Neck Expert</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img4.jpg" alt="image">
                        <a routerLink="/single-team" class="details-btn"><i class="flaticon-add"></i></a>
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-team">Joe Root</a></h3>
                        <span>Medicine Expert</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-progress-area ptb-120 bg-fff7f4">
    <div class="container">
        <div class="section-title text-center">
            <span>Progress</span>
            <h2>We ensure Your Desire Things</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-progress-box">
                    <div class="progress-bar progress-count" data-percent="90"></div>
                    <h3>Biotechnology</h3>
                    <p>Lorem ipsum dolor sit amet, consectur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-progress-box">
                    <div class="progress-bar progress-count" data-percent="80"></div>
                    <h3>Vaccines</h3>
                    <p>Lorem ipsum dolor sit amet, consectur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-progress-box">
                    <div class="progress-bar progress-count" data-percent="95"></div>
                    <h3>Medical research</h3>
                    <p>Lorem ipsum dolor sit amet, consectur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-area ptb-120">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">Testimonial</span>
            <h2>What’s Clients Response About</h2>
        </div>
    </div>

    <div class="testimonial-slides owl-carousel owl-theme">
        <div class="single-testimonial-item">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
            <div class="client-info">
                <img src="assets/img/author1.jpg" alt="image">
                <h3>James Andeson</h3>
                <span>Dentist Expert</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
            <div class="client-info">
                <img src="assets/img/author2.jpg" alt="image">
                <h3>Danis Max</h3>
                <span>Web Developer</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
            <div class="client-info">
                <img src="assets/img/author3.jpg" alt="image">
                <h3>Sarah Taylor</h3>
                <span>Web Designer</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
            <div class="client-info">
                <img src="assets/img/author4.jpg" alt="image">
                <h3>Joe Root</h3>
                <span>Business Man</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
            <div class="client-info">
                <img src="assets/img/author3.jpg" alt="image">
                <h3>Sarah Taylor</h3>
                <span>Web Designer</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>
    </div>
</section>