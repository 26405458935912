<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img6.jpg" alt="image"></a>
                                <div class="date">21 Jan, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">Anderson</a></span>
                                <h3><a routerLink="/single-blog">Remember, Only YOU can Prevent Genetic Drift</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img7.jpg" alt="image"></a>
                                <div class="date">12 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">John Smith</a></span>
                                <h3><a routerLink="/single-blog">Open letter to the residents of Ellsworth, Maine</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img8.jpg" alt="image"></a>
                                <div class="date">14 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">Joe Root</a></span>
                                <h3><a routerLink="/single-blog">What is a normal phenotype for aging mice?</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img9.jpg" alt="image"></a>
                                <div class="date">16 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">Sarah Taylor</a></span>
                                <h3><a routerLink="/single-blog">This burning question keeps postdocs up at night</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img1.jpg" alt="image"></a>
                                <div class="date">12 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">John Smith</a></span>
                                <h3><a routerLink="/single-blog">Quick Facts to Improve Antibody Half-Life Measurements</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img2.jpg" alt="image"></a>
                                <div class="date">14 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">Joe Root</a></span>
                                <h3><a routerLink="/single-blog">The Versatile Mouse Model for Rare Disease Research</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img3.jpg" alt="image"></a>
                                <div class="date">16 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">Sarah</a></span>
                                <h3><a routerLink="/single-blog">If you’re happy and you know it, thank your bugs</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog"><img src="assets/img/blog-img4.jpg" alt="image"></a>
                                <div class="date">28 Sep, 2019</div>
                            </div>
                            <div class="post-content">
                                <span>By: <a routerLink="/blog-2">James Bond</a></span>
                                <h3><a routerLink="/single-blog">Moving forward at the National Cancer Institute</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <a routerLink="/single-blog" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog-2" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/blog-2" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/blog-2" class="page-numbers">3</a>
                            <a routerLink="/blog-2" class="page-numbers">4</a>
                            <a routerLink="/blog-2" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_zylon_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How to change yourself for the better</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">10 Tactics for marketing your company</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Google web ranking changing much</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_recent_comments">
                        <h3 class="widget-title">Recent Comments</h3>

                        <ul>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">A WordPress Commenter</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">Semental</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">Wordpress</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">A WordPress Commenter</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-1">Semental</a>
                                </span>
                                on <a routerLink="/blog-1">Hello world!</a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/single-blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/single-blog">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/single-blog">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/single-blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/single-blog">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-1">May 2019</a></li>
                            <li><a routerLink="/blog-1">April 2019</a></li>
                            <li><a routerLink="/blog-1">June 2019</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-1">Business</a></li>
                            <li><a routerLink="/blog-1">Privacy</a></li>
                            <li><a routerLink="/blog-1">Technology</a></li>
                            <li><a routerLink="/blog-1">Tips</a></li>
                            <li><a routerLink="/blog-1">Uncategorized</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/blog-1">Log in</a></li>
                            <li><a routerLink="/blog-1">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog-1">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog-1">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog-1">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-1">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-1">IT <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-1">Marketing <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-1">Mobile <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Protect <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Startup <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>