<section class="error-area">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/flag-bg.png" alt="error" style="z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;">
            <img src="assets/img/doom.png" alt="error">
            <h3 style="color: white">THIS DOMAIN HAS BEEN SEIZED</h3>
            <p style="color: #a09e9e">By the Department of Omnipotent Management (DOOM) in accordance with a seizure warrant obtained by the United States Attorney’s Office for the Southern District of New York and issued pursuant to 18 U.S.C § 981 by the United States District Court for the Southern District of New York.</p>
            <p style="color: #a09e9e">(Totally not fake)</p>
        </div>
    </div>
</section>
