<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Cart</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cart</li>
            </ul>
        </div>
    </div>
</div>

<section class="cart-area ptb-120">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products"><img src="assets/img/shop-img1.jpg" alt="item"></a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Analytical Balances 2</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$14.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$14.00</span>
                                <a routerLink="/cart" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products"><img src="assets/img/shop-img2.jpg" alt="item"></a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Precision Balances 1</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$20.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$20.00</span>
                                <a routerLink="/cart" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products"><img src="assets/img/shop-img3.jpg" alt="item"></a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Precision Balances 2</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$25.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$25.00</span>
                                <a routerLink="/cart" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products"><img src="assets/img/shop-img4.jpg" alt="item"></a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Precision Balances 3</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$10.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$10.00</span>
                                <a routerLink="/cart" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="continue-shopping-box">
                            <a routerLink="/products" class="btn btn-secondary">Continue Shopping</a>
                        </div>
                    </div>

                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <a routerLink="/cart" class="btn btn-primary">Update Cart</a>
                    </div>
                </div>
            </div>

            <div class="cart-totals">
                <h3>Cart Totals</h3>
                <ul>
                    <li>Subtotal <span>$150.00</span></li>
                    <li>Shipping <span>$30.00</span></li>
                    <li>Total <span><b>$180.00</b></span></li>
                </ul>
                <a routerLink="/checkout" class="btn btn-primary">Proceed to Checkout</a>
            </div>
        </form>
    </div>
</section>